#main .banner_contents .banner_wrap .banner_g .slick-slide {
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out; }

#main, #main .banner_contents .banner_wrap .button_b, #contents .contents_wrap ul {
  font-size: 0;
  letter-spacing: 0;
  font-weight: normal; }

a {
  text-decoration: none; }

.f1, .top_c_title, .f1M, #info ul li a .date, .f1B, #contents .contents_wrap ul li p {
  font-family: 'Barlow Condensed', sans-serif; }

.f1, .top_c_title {
  font-weight: 400; }

.f1M, #info ul li a .date {
  font-weight: 500; }

.f1B, #contents .contents_wrap ul li p {
  font-weight: 600; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

#main {
  width: 100%; }
  #main .main_img {
    position: relative; }
    #main .main_img:before {
      content: '';
      padding-top: 48%; }
    #main .main_img img {
      width: 100%; }
  #main .banner_contents {
    background: #DBDEE0;
    padding: 20px 0 40px; }
    #main .banner_contents .banner_wrap {
      width: 960px;
      margin: auto;
      position: relative; }
      #main .banner_contents .banner_wrap .b_return, #main .banner_contents .banner_wrap .b_next {
        display: block;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        position: absolute;
        top: 50%;
        -webkit-transition: all .3s ease-out;
        transition: all .3s ease-out; }
      #main .banner_contents .banner_wrap .b_return {
        border-right: 10px solid #243945;
        left: -30px; }
        #main .banner_contents .banner_wrap .b_return:hover {
          left: -35px; }
      #main .banner_contents .banner_wrap .b_next {
        border-left: 10px solid #243945;
        right: -30px; }
        #main .banner_contents .banner_wrap .b_next:hover {
          right: -35px; }
      #main .banner_contents .banner_wrap .banner_g {
        overflow: hidden; }
        #main .banner_contents .banner_wrap .banner_g .slick-slide {
          width: 314px;
          display: inline-block;
          margin-right: 10px;
          -webkit-transition: all .2s ease-out;
          transition: all .2s ease-out; }
        #main .banner_contents .banner_wrap .banner_g a {
          outline: none; }
          #main .banner_contents .banner_wrap .banner_g a:hover {
            opacity: .7; }
          #main .banner_contents .banner_wrap .banner_g a img {
            display: block;
            width: 100%;
            height: auto; }
      #main .banner_contents .banner_wrap .button_b {
        width: auto;
        float: right;
        margin-top: 12px; }
        #main .banner_contents .banner_wrap .button_b li {
          display: inline-block; }
          #main .banner_contents .banner_wrap .button_b li.slick-active button {
            background: #243945; }
        #main .banner_contents .banner_wrap .button_b button {
          font-size: 0;
          display: inline-block;
          width: 12px;
          height: 12px;
          background: #fff;
          margin-left: 12px;
          -webkit-transition: all .3s ease-out;
          transition: all .3s ease-out; }
          #main .banner_contents .banner_wrap .button_b button.current, #main .banner_contents .banner_wrap .button_b button:hover {
            background: #243945; }

.top_c_title {
  font-size: 50px;
  letter-spacing: .2em;
  margin-bottom: 25px; }

#info {
  width: 960px;
  margin: 70px auto 60px;
  position: relative; }
  #info .more {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: .1em;
    text-decoration: none;
    width: 100px;
    height: 26px;
    background: #DBDEE0;
    display: block;
    text-align: center;
    line-height: 26px;
    position: absolute;
    top: 10px;
    right: 0;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out; }
    #info .more:hover {
      background: #243945;
      color: #fff; }
  #info ul li {
    width: 100%;
    background: #F2F2F2;
    min-height: 60px;
    margin-bottom: 15px;
    line-height: 1.5em;
    padding-right: 20px;
    overflow: hidden;
    position: relative; }
    #info ul li:after {
      content: '';
      width: 100px;
      height: 100%;
      display: block;
      background: #243945;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transition: all .4s .2s ease-out;
      transition: all .4s .2s ease-out; }
    #info ul li:hover:after {
      width: 100%; }
    #info ul li:hover a {
      color: #fff; }
    #info ul li a {
      text-decoration: none;
      font-size: 14px;
      font-weight: bold;
      display: block;
      color: #000;
      position: relative;
      z-index: 3;
      -webkit-transition: all .3s ease-out;
      transition: all .3s ease-out; }
      #info ul li a .date {
        width: 100px;
        height: 100%;
        color: #fff;
        display: inline-block;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: .1em;
        line-height: 60px;
        margin-right: 25px;
        vertical-align: top; }
      #info ul li a .info_text {
        display: inline-block;
        width: calc(100% - 100px - 30px);
        height: auto;
        line-height: 1.4;
        padding: 20px 0;
        overflow: hidden;
        font-size: 14px; }

#contents .contents_title {
  width: 960px;
  margin: auto; }

#contents .contents_wrap {
  padding: 20px 0;
  background: #F2F2F2;
  margin-top: 25px; }
  #contents .contents_wrap ul {
    width: 960px;
    margin: auto; }
    #contents .contents_wrap ul li {
      width: calc(960px / 4 - 10px);
      height: calc(960px / 4 - 10px);
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 10px;
      vertical-align: top;
      position: relative;
      text-align: center; }
      #contents .contents_wrap ul li:hover, #contents .contents_wrap ul li.selected {
        opacity: .8; }
        #contents .contents_wrap ul li:hover p:after, #contents .contents_wrap ul li.selected p:after {
          -webkit-animation: b_ani .4s linear;
                  animation: b_ani .4s linear; }
      #contents .contents_wrap ul li.info {
        background: #A71E40; }
        #contents .contents_wrap ul li.info img {
          width: 52px; }
      #contents .contents_wrap ul li.movie {
        background: #B77100; }
        #contents .contents_wrap ul li.movie img {
          width: 76px; }
      #contents .contents_wrap ul li.radio {
        background: #A41E0E; }
        #contents .contents_wrap ul li.radio img {
          width: 59px; }
      #contents .contents_wrap ul li.photo {
        background: #1B3E5A; }
        #contents .contents_wrap ul li.photo img {
          width: 64px; }
      #contents .contents_wrap ul li.staff {
        background: #1B3E5A; }
        #contents .contents_wrap ul li.staff img {
          width: 29px; }
      #contents .contents_wrap ul li.ticket {
        background: #A41E0E; }
        #contents .contents_wrap ul li.ticket img {
          width: 61px; }
      #contents .contents_wrap ul li.goods {
        background: #B77100; }
        #contents .contents_wrap ul li.goods img {
          width: 33px; }
      #contents .contents_wrap ul li.special {
        background: #6A8400; }
        #contents .contents_wrap ul li.special img {
          width: 105px; }
      #contents .contents_wrap ul li a {
        display: block; }
      #contents .contents_wrap ul li img {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        margin-top: -20px; }
      #contents .contents_wrap ul li p {
        font-size: 28px;
        color: #fff;
        letter-spacing: .2em;
        margin-top: 170px;
        display: inline-block;
        position: relative;
        padding-left: 5px; }
        #contents .contents_wrap ul li p:after {
          content: '';
          display: block;
          width: 100%;
          height: 4px;
          position: absolute;
          bottom: -8px;
          left: 0;
          background: #fff; }

@-webkit-keyframes b_ani {
  0% {
    width: 0%; }
  40% {
    width: 0%; }
  100% {
    width: 100%; } }

@keyframes b_ani {
  0% {
    width: 0%; }
  40% {
    width: 0%; }
  100% {
    width: 100%; } }

@media screen and (max-width: 767px) {
  #main {
    width: 100%; }
    #main .banner_contents {
      padding: 30px 0 20px; }
      #main .banner_contents .banner_wrap {
        width: 100%; }
        #main .banner_contents .banner_wrap .b_return, #main .banner_contents .banner_wrap .b_next {
          display: none !important; }
        #main .banner_contents .banner_wrap .banner_g .slick-slide {
          width: 80vw;
          margin: 0 2vw; }
        #main .banner_contents .banner_wrap .button_b {
          width: auto;
          text-align: center;
          float: inherit;
          margin-top: 15px; }
  .top_c_title {
    font-size: 40px;
    text-align: left;
    margin-bottom: 20px; }
  #info {
    width: 100%;
    margin: 20px auto 80px;
    padding: 0 20px; }
    #info .more {
      top: inherit;
      bottom: -50px;
      right: inherit;
      left: 50%;
      -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0); }
    #info ul li {
      height: auto;
      margin-bottom: 14px;
      line-height: 1.4;
      padding-right: 20px;
      min-height: inherit; }
      #info ul li:after {
        display: none; }
      #info ul li:hover a {
        color: #000; }
      #info ul li a {
        position: relative; }
        #info ul li a .date {
          line-height: 1.4;
          margin-right: 20px;
          display: inline-block;
          vertical-align: top;
          line-height: 95px;
          height: 95px;
          background: #243945; }
        #info ul li a .info_text {
          display: inline-block;
          width: calc(100% - 100px - 30px);
          max-height: 84px;
          font-size: 14px;
          padding: 5px 0;
          line-height: 1.4;
          position: absolute;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%); }
  #contents .contents_title {
    width: 100%;
    padding: 0 20px; }
  #contents .contents_wrap {
    padding: 20px 0; }
    #contents .contents_wrap ul {
      width: 100%;
      padding: 0 20px; }
      #contents .contents_wrap ul li {
        width: calc(100vw / 2 - 25px);
        height: 67px;
        margin-right: 10px;
        margin-bottom: 8px; }
        #contents .contents_wrap ul li:nth-of-type(2n) {
          margin-right: 0; }
        #contents .contents_wrap ul li.info {
          background: #A71E40; }
          #contents .contents_wrap ul li.info img {
            width: 20px;
            left: 14%; }
          #contents .contents_wrap ul li.info p {
            letter-spacing: .1em; }
        #contents .contents_wrap ul li.movie {
          background: #B77100; }
          #contents .contents_wrap ul li.movie img {
            width: 30px; }
        #contents .contents_wrap ul li.radio {
          background: #A41E0E; }
          #contents .contents_wrap ul li.radio img {
            width: 27px;
            left: 12%; }
        #contents .contents_wrap ul li.photo {
          background: #1B3E5A; }
          #contents .contents_wrap ul li.photo img {
            width: 32px; }
        #contents .contents_wrap ul li.staff {
          background: #1B3E5A; }
          #contents .contents_wrap ul li.staff img {
            width: 15px;
            left: 16%; }
        #contents .contents_wrap ul li.ticket {
          background: #A41E0E; }
          #contents .contents_wrap ul li.ticket img {
            width: 30px; }
        #contents .contents_wrap ul li.goods {
          background: #B77100; }
          #contents .contents_wrap ul li.goods img {
            width: 18px;
            left: 15%; }
        #contents .contents_wrap ul li.special {
          background: #6A8400; }
          #contents .contents_wrap ul li.special img {
            width: 50px;
            left: 10%; }
        #contents .contents_wrap ul li a {
          display: block; }
        #contents .contents_wrap ul li img {
          position: absolute;
          top: 50%;
          left: 15%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%);
          margin-top: 0; }
        #contents .contents_wrap ul li p {
          font-size: 16px;
          margin-top: 25px;
          padding-left: 0;
          margin-left: 50px; }
          #contents .contents_wrap ul li p:after {
            width: 100%;
            height: 2px;
            position: absolute;
            bottom: -6px;
            left: 0; } }
